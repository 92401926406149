<template>
      
<div class="treeview">


            <span class="btn  btn-vtl"><i class="mdi mdi-folder-outline"></i> Categorías del presupuesto</span>
            
            <div class="vtl-wrapper">


              <p v-if="!this.data.children">No hay conceptos aún</p>


              

              <!---@click="null"
              @change-name="null"--->

              <vue-tree-list :key="key"
              @delete-node="onDel"
              @add-node="onAddNode"
              @drop="onDrop"
              :model="data"
              default-tree-node-name=""
              default-leaf-node-name=""
              v-bind:default-expanded="true"  

              ref="tree"
            >
              <template v-slot:leafNameDisplay="slotProps">
                  {{ slotProps.model.name }} <span class="pull-right">{{ $numberFormat(slotProps.model.amount) }}</span>
              </template>


              
              
              

              <!--
              <template v-slot:addTreeNodeIcon="slotProps">
                
                <span @click="onAddNodeClick(slotProps)" class="icon"><i class="vtl-icon vtl-icon-folder-plus-e"></i></span>
              </template>
            -->
              <template v-slot:editNodeIcon="slotProps">
                <span @click="onEditNodeClick(slotProps)" class="icon"><i class="vtl-icon vtl-icon-edit"></i></span>
              </template>
              <template v-slot:addLeafNodeIcon="slotProps">
                <i @click="onAddLeaf(slotProps)"></i>
              </template>



              <!--
                <span class="icon" slot="addTreeNodeIcon">📂</span>
                <span class="icon" slot="addLeafNodeIcon">＋</span>
              <span class="icon" slot="editNodeIcon">📃</span>
              <span class="icon" slot="delNodeIcon">✂️</span>
              <span class="icon" slot="leafNodeIcon">🍃</span>
              <span class="icon" slot="treeNodeIcon">🌲</span>
              -->
            </vue-tree-list>
            <b-modal v-model="budget_dialog" ref="modalBudget" @hidden="cancelBudget" id="form-modal" size="sm" title="Agregar elemento" :hide-footer="true">


              <form @submit.prevent="addBudget">
                <div class="form-group">

                  <label>Título</label>
                  <input class="form-control" required v-model="budget.name" type="text" placeholder="Escribe el título"/>

                </div>
                <div class="form-group">

                  <label>Cantidad presupuestada</label>
                  

                  <b-input-group class="mt-3">
                      <template #append>
                          <select v-model="budget.currency_id" class="form-control form-control-lg">
                              <option v-for="(option, index) in currencies" :key="index" :value="option.id">{{ option.name }}</option>
                          </select>
                      </template>
                      <input v-number-format required class="form-control form-control-lg" type="text" v-model="budget.amount" placeholder="Escribe la cantidad"/>
                  </b-input-group>


                </div>

                <!---<input type="submit" class="btn btn-gradient-primary" v-bind:value="LabelSave"/>-->

                <submit-button :label="LabelSave" :label_loading="'Cargando...'" :loading_flag="loading"></submit-button>

                <button class="btn btn-gradient-default" @click="budget_dialog=false">Cancelar</button>
              </form>


            </b-modal>
            <br>

            <a href="javascript:void(0)" class="action-link" @click="addNode"><i class="mdi mdi-folder-plus-outline"></i> Agregar grupo</a>

            </div><!---.vtl-wrapper-->
            




        </div>
            


</template>

<script>

import api from '@/util/api.js';
import { VueTreeList, Tree, TreeNode } from 'vue-tree-list';
import CircularJSON from 'circular-json';

import submitButton from '@/components/ui-feature/submit-button';





export default {
  routeName:'budgets',
  label:'Presupuesto',
  name: 'treeBudget',
  components: {
    VueTreeList,
    submitButton
  },

  props:{


    items:{
      type:Array,
      required:true

    },

    development_id:{
      type:Number,
      required:false

    },
    currencies:{
      type:Array,
      required:false

    }

  },


  computed:{

    LabelSave(){

      return 'Guardar';
      //return this.isNewRecord?'Agregar':'Actualizar';

    },

  },

  data() {
    
    return{


      key:0,
      loading:false,
      current_node:{},
      newTree: {},

      budget_dialog:false,
      budget:{

        name:'',
        amount:0,
        currency_id:0,

    },

    data:{

      model:{
        name:'',
        currency_id:0
        
      }

    }

    }

  },
      
  

  methods: {

    onAddLeaf(){


    },
    


    onDrop(){

      this.afterChange();

    },  

    addBudget(){



      this.current_node.model.name=this.budget.name;      
      this.current_node.model.amount=this.budget.amount;
      this.current_node.model.currency_id=this.budget.currency_id;
      this.budget={name:''};
      this.key++;

      this.isNewRecord=false;
      this.afterChange();


    },

    cancelBudget(){

      this.budget_dialog=false;
      this.budget={};

      if(this.isNewRecord){
        this.current_node.delNode();
        this.isNewRecord=false;
        
      }


    },

    beforeNodeInsertion(){


    },


    /*

    onAddNodeClick(e){

      //e.model.children[0].name='Nombre del nodo';
      console.log(e);

  //    this.$refs.modalBudget.show();

    },*/

    onEditNodeClick(params){


      this.isNewRecord=false;
      


      this.$refs.modalBudget.show();

      

      
      this.$nextTick(() => {


        const node=this.findById(this.$refs.tree.$children,params.model.id);

        this.current_node=node;

        

          this.budget.name=node.model.name;
          this.budget.amount=this.$numberFormat(node.model.amount);
          this.budget.currency_id=node.model.currency_id;


        });


    },


    onDel(node) {

      
      if(this.isNewRecord || confirm('¿Eliminar?')){


        


        api.delete(this.me('/'+node.id)).then(()=>{

          node.remove();
          this.$notify({message:"Elemento eliminado",type:"success"});


        }).catch(error=>{

          if(error.response.status==404){
            node.remove();
          }else{
            this.$notify({message:"No se pudo eliminar el elemento porque ya está en uso",type:"error"});
          }

        });

        

        this.$emit('afterChange',this.data);

        
 //       this.afterChange();
        
      }
    
      
      
    
    },


    /*
      onChangeName(params) {


        console.log(params);

      },*/



      onAddNode(params) {

        //

        
        this.isNewRecord=true;
        
        this.$nextTick(() => {

            const node=this.findById(this.$refs.tree.$children,params.id);
  
          
            
            this.current_node=node;
  
            //node.delNode();
//            node.setEditable();
        });

        this.budget_dialog=true;

        //node.setEditable();
        //console.log(params)

        //const index=this.data.findChildIndex(params);
       // console.log(index);

      },

      /*
      onClick(params) {

          
            const node=this.findById(this.$refs.tree.$children,params.id);
            
            console.log(node);
            //node.setEditable();

            console.log(params);


      },*/

      findById(data, id) {

        
          
          for (let i in data) {
            
              let item=data[i];
            

            if (item.$options.propsData.model.id == id) {
              return item;
            } else if (item.$children) {
              const exists = this.findById(item.$children, id);
              if (exists) {
                return exists;
              }
            }
          }
          return null;
        },


      addNode() {
        var node = new TreeNode({ name: '', isLeaf: false,editable:true,addLeafNodeDisabled:true});
        if (!this.data.children) this.data.children = []
        this.data.addChildren(node);

      //  console.log(node);
        //const index=this.data.findChildIndex(node);

        
        
        
        
        
        
        this.$refs.tree.$emit('add-node', node);
        //this.$refs.tree.$emit('update:editable', true);


        /*
        console.log(node);

        this.$nextTick(() => {
          const $input = node.$refs.nodeInput
          $input.focus()
          $input.removeAttribute('disabled')
        })*/

        

      },


      
      getNewTree() {
        var vm = this
        function _dfs(oldNode) {
          var newNode = {}

          for (var k in oldNode) {
            if (k !== 'children' && k !== 'parent') {
              newNode[k] = oldNode[k]
            }
          }

          if (oldNode.children && oldNode.children.length > 0) {
            newNode.children = []
            for (var i = 0, len = oldNode.children.length; i < len; i++) {
              newNode.children.push(_dfs(oldNode.children[i]))
            }
          }
          return newNode
        }

        vm.newTree = _dfs(vm.data)
      },

    getItems() {

      this.listing=true;
      
      api.get(this.me('?development_id='+this.$props.development_id)).then(response => {
        this.items = response.data;

        
      }).catch(error => {
        console.log(error);
      }).finally(()=>{

        this.listing=false;

      });
    },


    deleteMe(id,index){





      if(confirm('¿Desea eliminar el elemento?')) {

              api.delete(this.me(id)).then(()=>{

                this.items.splice(index,1);
                this.$forceUpdate();
                
              
              }).catch(error=>{

                console.log(error);
                
              });

        }

      },


      afterChange(){

        this.$emit('afterChange',this.data);      
        this.saveBudget();

      },


      saveBudget(){

        const jsonString = CircularJSON.stringify(this.data);
        const data=new FormData();
        data.append('budgets',jsonString);

        this.loading=true;

        api.post('/developments/savebudgets/'+this.$props.development_id,data).then(response=>{

          this.data=new Tree(response.data.budgets);

          this.budget_dialog=false;

        }).catch((error)=>{


          console.log(error);

        }).finally(()=>{

          this.loading=false;

        });



      }
          

      
  },

  mounted(){


      this.data=this.items;
      this.data=new Tree(this.data);

  },


  

}



</script>

<style lang="scss">

.vtl-node-content{width:90%}

.vtl-operation{display: inline-block !important;}

span[title="Add Leaf Node"]{display:none}

.vtl-node-main{cursor:move}

</style>